<template>
  <div class="progress-container">
    <header class="index-header">
      <div class="quiz-index">{{quizIndex + 1}}/{{maxQuizLength}}</div>
      <span class="question-mark">{{quizIndex + 1 === maxQuizLength ? '!' : "?"}}</span>
    </header>
    <div class="progress-bar">
      <div class="indicator" :style="{transform: `translate(${quizIndex * 18 - 4}px, -12px)`}">
        <div class="circle"></div>
      </div>
      <div
        v-for="cell in maxQuizLength"
        :class="['cell', quizIndex >= cell && 'filled']"
        :key="`cell-${cell}`"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    quizIndex: {
      type: Number,
      default() {
        return 0;
      }
    },
    maxQuizLength: {
      type: Number,
      default() {
        return 12;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.progress-container {
  width: 290px;
  margin: 80px auto 60px;
  header.index-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .quiz-index {
      font-size: 18px;
      font-weight: bold;
      color: #583c14;
    }
  }
  .progress-bar {
    border: 3px solid #897a65;
    border-radius: 20px;
    height: 15px;
    display: flex;
    .indicator {
      transition: 0.3s ease-in;
      position: absolute;
      padding: 8px;
      border-radius: 50%;
      background-color: #fdb7ab;
      display: flex;
      justify-content: center;
      align-items: center;
      .circle {
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #fe647e;
      }
    }
  }
  .cell {
    height: 9px;
    width: 100%;
    border-right: 1px solid #897a65;
    &.filled {
      background-color: #82705c;
      border-right-color: #8a7965;
      &:first-child {
        border-radius: 0 0 0 5px;
      }
    }
    &:first-child {
      padding-left: 2px;
      border-radius: 3px 0 0 3px;
    }
    &:last-child {
      border-right: none;
      padding-right: 2px;
      border-radius: 0 3px 3px 0;
    }
  }
}
</style>