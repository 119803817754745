<template>
  <div
    v-if="!showLoading"
    class="container test"
    :style="{borderColor: borderColor, boxShadow: boxShadow}"
  >
    <Clips style="margin-left: 30px; right: -2px;" :quizIndex="quizIndex" />
    <Progress :quizIndex="quizIndex" :maxQuizLength="quiz.length" />
    <transition-group name="slide" tag="div" class="div-slider" v-for="(q, i) in quiz" :key="i">
      <header
        v-if="i === quizIndex && quizTransition"
        :key="`header-${i}`"
        class="quiz-title"
        v-html="q.q"
      ></header>
      <div v-if="i === quizIndex && quizTransition" class="cover" :key="`cover-${i}`">
        <picture class="test-img">
          <img :src="`/quiz/${i + 1}.png`" :alt="`${i + 1}.png`" />
        </picture>
        <button
          v-for="(a, i) in q.a"
          class="quiz"
          :key="`answer-${i}`"
          @click="getScore(a.type)"
          :style="{background: btnBgColor}"
        >
          <span v-html="a.txt"></span>
          <div class="post-fold">
            <div class="folded" :style="{borderColor: foldedColor}"></div>
          </div>
        </button>
      </div>
    </transition-group>
  </div>
  <div v-else id="loading-container">
    <img src="/card/loading.png" alt="???? ?????? ???????" />
    <div class="loading-gauge">
      <div class="fill" :style="{width: `${loadingGauge}%`}"></div>
    </div>
    <div class="loading-title">깡 분석 중...</div>
  </div>
</template>
<script>
import Progress from "@/components/Progress.vue";
import Clips from "@/components/Clips.vue";
import firebase from "firebase/app";
import "firebase/firestore";
export default {
  name: "Test",
  components: {
    Progress,
    Clips
  },
  data() {
    return {
      quizIndex: 0,
      quizTransition: true,
      showLoading: false,
      loadingGauge: 0,
      result: {
        열정: 0,
        소극: 0,
        배려: 0,
        자기중심: 0,
        센스: 0,
        FM: 0
      },
      quiz: [
        {
          q: "출근하는 길 나의 출근 방식은?",
          a: [
            {
              txt: "대중교통 어플로 시간을 계산하여<br/>늦지 않게 출근한다.",
              type: "FM"
            },
            {
              txt:
                "조금 여유롭게 나와서 오는<br/>대중교통을 이용하여 출근한다.",
              type: "센스"
            }
          ]
        },
        {
          q: "출근 후 탕비실로 직행, 인기있는 커피가 하나만 남았네.. 어떡하지?",
          a: [
            {
              txt: "나 말고 다른 분들도 좋아하는 거니 오늘은 차를 마셔야지.",
              type: "배려"
            },
            {
              txt: "어차피 다시 채워주실텐데 그냥 먹자~",
              type: "자기주장"
            }
          ]
        },
        {
          q: "출근 후 업무 중 새로 온 인턴이 헤매고 있다.. 이때 나의 행동은?",
          a: [
            {
              txt: "먼저 다가가 친절하게 설명하며 격려한다.",
              type: "열정"
            },
            {
              txt: "나에게 물어보면 그때 알려준다.",
              type: "소극"
            }
          ]
        },
        {
          q:
            "프로젝트 회의 중 새로운 아이디어로 팀원들 간 의견이 갈리는 상황, 이때 드는 생각은?",
          a: [
            {
              txt: "이때까지 진행해온 것도 일단은 계획대로 진행해본다.",
              type: "FM"
            },
            {
              txt:
                "더 좋은 결과가 있을 수 있잖아?<br/>다양한 시도를 해보는 게 좋지 않을까?<br/> 계획을 변경하여 진행한다.",
              type: "센스"
            }
          ]
        },
        {
          q:
            "인수인계를 받은 내 후배가 내가 해왔던 방식과는 다르게 업무에 접근을 하려 한다. 이때 드는 생각은?",
          a: [
            {
              txt: "분명 내가 가르쳐준대로 하면 효율적인데 왜 저렇게 하지?",
              type: "FM"
            },
            {
              txt:
                "저렇게 해도 문제는 없으니까! 더 편한 방법이면 저렇게 하라 해야지~",
              type: "센스"
            }
          ]
        },
        {
          q:
            "동기들과 즐거운 점심시간, 내가 어제 먹었던 짜장면으로 대화 주제가 바뀌고 있는데, 흠...",
          a: [
            {
              txt: `"난 다 좋아~"<br/>그냥 아무 말 않고 따라간다.`,
              type: "배려"
            },
            {
              txt: `"요 앞에 순대국집 새로 생겼다던데~"<br/>다른 것이 먹고 싶기 때문에<br/>다른 메뉴를 언급해 본다.`,
              type: "자기주장"
            }
          ]
        },
        {
          q:
            "커피를 사러 사내카페에 왔는데 열심히 근무 중인 팀원들이 생각난다. 나의 행동은? (팀원 6명)",
          a: [
            {
              txt:
                "고생하고 있을 팀원들을 위해 시원한<br/>아바라를 팀원들 머릿수대로 주문한다.",
              type: "센스"
            },
            {
              txt: "6명은 조금 부담되는데...?<br/>그냥 내 것만 산다.",
              type: "FM"
            }
          ]
        },
        {
          q:
            "바쁘게 업무를 처리하고 있는데 사내 메신저로 친한 선배가 “안바쁘면 커피 한 잔하자! 바쁘면 업무보고!”라는 메시지가 왔다. 어떻게 할 것인가?",
          a: [
            {
              txt:
                "할 일이 좀 있지만 잠깐이라도<br/>나가서 선배와 커피타임을 갖는다.",
              type: "센스"
            },
            {
              txt:
                "바쁘고 업무가 늦어지면 제출이<br/>늦어질 수 있으니<br/>업무를 보겠다고 잘 말한다.",
              type: "FM"
            }
          ]
        },
        {
          q: "큰 프로젝트 마감 후 잠깐 쉴 시간이 생겼다. 이때 나는?",
          a: [
            {
              txt: "그래도 할 일이 산더미인데.. 계속해서 업무를 본다.",
              type: "열정"
            },
            {
              txt:
                "휴 피곤하다... 팀 분위기를 살피고 자리에서 조용히 조금 휴식한다",
              type: "소극"
            }
          ]
        },
        {
          q: "급히 미팅하러 가는 중, 다른 팀 동기가 서류들을 떨어뜨렸는데...",
          a: [
            {
              txt: "회의에 아슬아슬하게 도착하더라도 도와주자...!",
              type: "배려"
            },
            {
              txt: "도와주고 싶지만 회의에 늦으면 눈치보여...",
              type: "자기중심"
            }
          ]
        },
        {
          q:
            "자자 퇴근합시다! 금요일이니까 친구들도 만나고 맛있는 밥도 드시고 허허허! 칼퇴하자는 팀장님. 30분 정도만 더 하면 마무리되는 업무가 있는데...",
          a: [
            {
              txt: "이것만 하다가 가겠습니다!<br/>팀장님 좋은 주말 보내십시오!",
              type: "열정"
            },
            {
              txt: `자연스럽게 엘레베이터에<br/>"손이가요 손이가"<br/>남은 일은 내일의 나에게…`,
              type: "소극"
            }
          ]
        },
        {
          q:
            "퇴근 후 동기들과의 회식<br/>메뉴는 소고기!! 정중앙에 놓인<br/>집게와 가위가 눈에 들어온다.",
          a: [
            {
              txt: "나는 집게손, 가위손! 열심히 고기 굽자!",
              type: "배려"
            },
            {
              txt: "아 맛있다.. ㅎㅎ<br/>조용히 젓가락만 움직여야지.",
              type: "자기중심"
            }
          ]
        },
        {
          q:
            "드디어 집이다... 고생한 날 위해<br/>이제 푹 쉬어야지~ 이때<br/>갑자기 온 팀원의 연락..",
          a: [
            {
              txt: "응응, 아 그거! 내가 바로<br/>지금 수정해서 보내줄게",
              type: "배려"
            },
            {
              txt: "(다음날)<br/>미안 피곤해서 잠들었었다…<br/>무슨 일 있었어?",
              type: "자기중심"
            }
          ]
        },
        {
          q: "주말인데.. 회사 워크샵으로<br/>등산을 오게 됐다. 나의 행동은?",
          a: [
            {
              txt: "부장님! 저만 믿고 따라오세요~<br/>팀원들을 이끈다.",
              type: "열정"
            },
            {
              txt:
                "‘아.. 이게 뭐야. 집에서 자고있을 시간에...’<br/>그냥 말없이 따라간다.",
              type: "소극"
            }
          ]
        },
        {
          q:
            "사내 체육대회가 있다는 소식을 들었다 팀의 위상을 높일 절호의 기회! 체육대회에서 나의 행동은?",
          a: [
            {
              txt: "응원이 체질! 경기에 참가하는 팀원을 응원한다!",
              type: "소극"
            },
            {
              txt:
                "질 수 없지! 잘하는 종목에 직접 나서서 경기에 열정적으로 임한다",
              type: "열정"
            }
          ]
        }
      ]
    };
  },
  computed: {
    borderColor() {
      if (this.quizIndex >= 11) {
        return "#b0d1fa";
      }
      if (this.quizIndex >= 7) {
        return "#b4eeb1";
      }
      if (this.quizIndex >= 5) {
        return "#fdefae";
      }
      if (this.quizIndex >= 2) {
        return "#d8b0f9";
      }
      return "#fdb7ab";
    },
    boxShadow() {
      if (this.quizIndex >= 11) {
        return `2px 2px 0 #ffffff, 4px 4px 0 #b0d1fa, 6px 6px 0 #ffffff, 8px 8px 0 #b0d1fa`;
      }
      if (this.quizIndex >= 7) {
        return `2px 2px 0 #ffffff, 4px 4px 0 #b4eeb1, 6px 6px 0 #ffffff, 8px 8px 0 #b4eeb1`;
      }
      if (this.quizIndex >= 5) {
        return `2px 2px 0 #ffffff, 4px 4px 0 #fdefae, 6px 6px 0 #ffffff, 8px 8px 0 #fdefae`;
      }
      if (this.quizIndex >= 2) {
        return `2px 2px 0 #ffffff, 4px 4px 0 #d8b0f9, 6px 6px 0 #ffffff, 8px 8px 0 #d8b0f9`;
      }
      return `2px 2px 0 #ffffff, 4px 4px 0 #fdb7ab, 6px 6px 0 #ffffff, 8px 8px 0 #fdb7ab`;
    },
    foldedColor() {
      if (this.quizIndex >= 11) {
        return "#a5c3db #ffffff";
      }
      if (this.quizIndex >= 7) {
        return "#a7dab1 #ffffff";
      }
      if (this.quizIndex >= 5) {
        return "#dcd29f #ffffff";
      }
      if (this.quizIndex >= 2) {
        return "#d5a6dd #ffffff";
      }
      return "#e0aba5 #ffffff";
    },
    btnBgColor() {
      if (this.quizIndex >= 11) {
        return "#cde2fd";
      }
      if (this.quizIndex >= 7) {
        return "#cef7cd";
      }
      if (this.quizIndex >= 5) {
        return "#fcefba";
      }
      if (this.quizIndex >= 2) {
        return "#f1d3f9";
      }
      return "#ffd4ce";
    }
  },
  watch: {
    quizIndex(to) {
      if (to >= 11) {
        document.body.style.backgroundColor = "#e6f2ff";
      } else if (to >= 7) {
        document.body.style.backgroundColor = "#e8ffe6";
      } else if (to >= 5) {
        document.body.style.backgroundColor = "#ffffe6";
      } else if (to >= 2) {
        document.body.style.backgroundColor = "#fae6ff";
      } else {
        document.body.style.backgroundColor = "#ffe9e6";
      }
    }
  },
  mounted() {
    this.quizIndex = 0;
    this.result = { 열정: 0, 소극: 0, 배려: 0, 자기중심: 0, 센스: 0, FM: 0 };
    window.scroll(0, 0);
  },
  methods: {
    getScore(type) {
      this.result[type] += 1;
      this.quizIndex += 1;
      this.quizTransition = false;
      setTimeout(() => {
        this.quizTransition = true;
      }, 130);
      if (this.quizIndex >= this.quiz.length) {
        document.body.style.backgroundColor = "#ffe9e6";
        const increment = firebase.firestore.FieldValue.increment(1);
        const docRef = this.$firestore.collection("user").doc("kkangbti");
        docRef.update({ finishCount: increment });
        this.showLoading = true;
        setInterval(() => {
          this.loadingGauge += 1;
        }, 30);
        setTimeout(() => {
          this.$router.push({
            name: "Result",
            params: {
              id: this.getResult()
            }
          });
        }, 3000);
      }
    },
    getResult() {
      const result = [
        this.result["열정"] > this.result["소극"] ? "열정" : "소극",
        this.result["배려"] > this.result["자기중심"] ? "배려" : "자기중심",
        this.result["센스"] > this.result["FM"] ? "센스" : "FM"
      ];

      const resultType = {
        열정자기중심센스: "새우깡",
        열정배려센스: "쌀새우깡",
        열정배려FM: "매운쌀새우깡",
        열정자기중심FM: "매운새우깡",
        소극배려센스: "감자깡",
        소극배려FM: "양파깡",
        소극자기중심센스: "옥수수깡",
        소극자기중심FM: "고구마깡"
      };

      return resultType[result.join("")];
    }
  }
};
</script>
<style lang="scss" scoped>
.slide-leave-active {
  transition: 0.1s;
}
.slide-enter-active {
  transition: 0.2s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.div-slider {
  overflow: hidden;
  position: static;
  width: 100%;
  max-width: 295px;
  .cover {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
#loading-container {
  width: 100%;
  max-width: 375px;
  padding: 0 20px;
  margin: auto;
  align-items: center;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 900px;
  .loading-gauge {
    border: 3px solid #624c34;
    border-radius: 20px;
    height: 15px;
    display: flex;
    width: 80%;
    margin-bottom: 20px;
    .fill {
      background-color: #624c34;
      height: 11px;
    }
  }
  .loading-title {
    font-size: 32px;
    color: #624c34;
  }
  & > img {
    width: 100%;
    max-width: 320px;
    margin-bottom: 40px;
  }
}
header.quiz-title {
  font-size: 18px;
  width: 95%;
  letter-spacing: -0.7px;
  text-align: center;
  margin: auto auto 50px;
  word-break: keep-all;
  font-weight: bold;
  color: #624c34;
  line-height: 1.5;
}
picture.test-img {
  height: 170px;
  margin: auto ;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    object-fit: contain;
  }
}
.post-fold {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  left: 20px;
  top: 0px;
  .folded {
    position: absolute;
    border-style: solid;
    border-width: 20px 20px 0 0;
    border-color: #dcd29f #ffffff;
    transition: all ease 0.5s;
  }
}
</style>